import React, {useState, Fragment} from "react"
import { navigate } from "gatsby"
import {Formik, Form, Field} from "formik"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as yup from "yup"
import "./index.css"
import WhyChoose from "../components/home/WhyChoose"
import RecentReviews from "../components/home/RecentReviews"
import { junkDescriptions } from "../data/productData"
function generateJunkHtml () {
  const options = junkDescriptions.map(i => <option key={i.name} value={i.value}>{i.name}</option>)
  return (
      <Fragment>
      <div className="leftcolumn"><label className="h4font" htmlFor="loadSize">Load Size: </label>
            <Field as="select" className="loadSize" name="loadSize">
              <option value="25">25% of truck</option>
              <option value="50">50% of truck</option>
              <option value="75">75% of truck</option>
              <option value="100">100% of truck</option>
            </Field>
      </div>
            <div className="centercolumn"><label className="h4font" htmlFor="desc">Description: </label>
              <div>
                <Field id="desc" name="description" className="labor" as="select">
                  {options}
                </Field>
              </div>
            </div>
            <div className="rightcolumn"><label className="h4font" htmlFor="zipCode">Zip Code:<br/></label><Field name="zip" id="zipCode" className="zip" type="text" placeholder="" />
          </div>
        <div className="submitbox"><button className="submit" type="submit">Submit</button>
        </div>
        </Fragment>)
}

function generateDumpsterHtml() {
return (<Fragment>
<div className="leftcolumn">
  <div><label className="h4font" htmlFor="dumpsterSize">Dumpster Size: </label>
            <Field as="select" className="dumpsterSize" name="dumpsterSize">
              <option value="10">10 Cubic Yards</option>
              <option value="15">15 Cubic Yards</option>
              <option value="20">20 Cubic Yards</option>
            </Field>
  </div>
</div>
      <div className="centercolumn"><label className="h4font" htmlFor="rentalDays">Rental Days: </label>
              <Field name="rentalDays" className="rental" placeholder="Days" type="number" min="1" step="1" />
      </div>
          <div className="rightcolumn"><label className="h4font" htmlFor="zipCode">Zip Code:<br/></label><Field name="zip" className="zip" type="text" placeholder="" />
          </div>
        <div className="submitbox"><button className="submit" type="submit">Submit</button>
        </div>
        <Field type="hidden" name="type" value="dumpster" />
</Fragment>)

}
const IndexPage = () => {
  const [tabSelected, setTabSelected] = useState(0);
  let junkClassName = "junk";
  let tabHtml;
  if(tabSelected !== 0){
    tabHtml = generateDumpsterHtml();
    junkClassName += " unselected";

  }

  let dumpsterClassName = "dumpster"
  if(tabSelected === 0){
    tabHtml = generateJunkHtml();
    dumpsterClassName += " unselected";

  }


  return (
  <Layout fluid={true}>
    <SEO title="Home" />
    <div className="background">
      <div className="backgroundimage"></div>
      <h1 className="title">Get rid of your junk{" "}<i>fast</i>.</h1>
      <p className="subtitle">Find local quotes on dumpster rental or junk removal services.</p>
      <Formik
        initialValues={{
          loadSize: 25,
          description: junkDescriptions[0].value,
          dumpsterSize: 10,
          rentalDays: 5
        }}
        validationSchema={() =>
          yup.object({
          zip: yup.string().defined()
        })}
        onSubmit={(values) => {
          var url = "/search/?"
          if (tabSelected === 0) {
            url = url + "type=junk";
            url = url + "&zip=" + values.zip;
            url = url + "&loadSize=" + values.loadSize;
            url = url + "&description=" + values.description;
            navigate(url);
          }
          if (tabSelected !== 0) {
            url = url + "type=dumpster";
            url = url + "&zip=" + values.zip;
            url = url + "&cubicYardSize=" + values.dumpsterSize;
            url = url + "&rentalDays=" + values.rentalDays;
            navigate(url);
          }
      }}>
        <>
        <div className="search">
          <div className="tabGrid">
            <button onClick={function(){
                setTabSelected(0)
              }} className={junkClassName}>Junk Removal</button>
              <button onClick={function(){
                setTabSelected(1)
              }} className={dumpsterClassName}>Dumpster Rental</button>
              <p className="tellmore">Tell us more to help us find a vendor</p>
          </div>
          <Form className="formGrid">

              {tabHtml}

          </Form>
        </div>
        </>
      </Formik>
    </div>
    <WhyChoose />
    <RecentReviews />
  </Layout>
)}

export default IndexPage
