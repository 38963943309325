import React from "react"
import styles from "./WhyChoose.module.css"
import commonStyles from "./common.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchDollar } from "@fortawesome/pro-light-svg-icons/faSearchDollar";
import { faUserCheck } from "@fortawesome/pro-light-svg-icons/faUserCheck";
import { faCommentCheck } from "@fortawesome/pro-light-svg-icons/faCommentCheck";
import { faClipboardCheck } from "@fortawesome/pro-light-svg-icons/faClipboardCheck";

export default function WhyChoose() {
  return (
    <section>
      <h2 className={commonStyles.header}>Why choose Junk Removal Dispatch</h2>
      <div className={styles.whyChoose}>
        <div className={styles.column}>
          <h2>Transparent Pricing</h2>
          <div><FontAwesomeIcon icon={faSearchDollar} size="6x" color="#000099"/></div>
          <p>
          We will provide you with clear pricing options from our service providers in a simple to you view formant.  No gimmicks.  No forms to fill out first.   You will not need to hunt and spent more time than you need figuring out how much our service is going to cost.
          </p>
        </div>
        <div className={styles.column}>
          <h2>Vetted Service Providers</h2>
          <div><FontAwesomeIcon icon={faUserCheck} size="6x" color="#000099"/></div>
          <p>
          Each Service Provider in our network is fully vetted.  This means a background check and insurance policies are checked and verified before they are allowed to join.  We understand how important it is to have confidence and trust in companies and individuals who enter our home.
          </p>
        </div>
        <div className={styles.column}>
          <h2>Verified Reviews</h2>
          <div><FontAwesomeIcon icon={faCommentCheck} size="6x" color="#000099"/></div>
          <p>
          We only allow reviews that have been received from previous customers of       our service.  You have the ability to rate our service provider once they finish.  We encourage reviews to help your community. 
          </p>
        </div>
        <div className={styles.column}>
          <h2>Performance Guarantee</h2>
          <div><FontAwesomeIcon icon={faClipboardCheck} size="6x" color="#000099"/></div>
          <p>
          We stand behind our service and our service providers.  If you have any issue or concern, let us know.  We are committed to resolve any issue in a timely manner.
          </p>
        </div>
      </div>
    </section>
  )
}
