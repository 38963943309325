import React from "react"
import Star from "../common/Star";
import styles from "./ReviewCard.module.css"

export default function ReviewCard({
  name,
  loc,
  reviewing,
  rating,
  desc,
  cost,
  service,
  size,
  date,
}) {
    let stars = [];
    if (rating) {
        for(let i = 1; i <= rating; i++) {
            stars.push(<Star key={`${i}-hollow`} filled={true} />)
        }
        for(let n = stars.length; n < 5; n++ ) {
            stars.push(<Star key={`${n}-filled`} filled={false} />)
        }
    }
  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <strong>{name}</strong>
      </div>
      <div className={styles.row}>
        Reviewing:  <strong>{reviewing}</strong>
      </div>
      <div className={styles.row}>
        <div className={styles.stars}>Rating: {stars}</div>
      </div>
      <div className={styles.row}>
        <span className={styles.small}>{desc}</span>
      </div>
      <div className={styles.table}>
            <div className={styles.leftCell}>Cost: {cost}</div>
            <div>Service: {service}</div>
            <div className={styles.leftCell}>Size: {size}</div>
            <div>Date: {date}</div>
        
      </div>
    </div>
  )
}
