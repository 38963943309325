import React from "react"
import styles from "./RecentReviews.module.css"
import commonStyles from "./common.module.css";
import ReviewCard from "./ReviewCard";
const reviews = [
  { 
    name: "Daniel G", 
    loc: "Kennesaw, GA", 
    reviewing: "Hullaway, LLC", 
    rating: 5,
    desc: "Amazing work, came early and finished early. Extremely professional, and greatly appreciated!",
    cost: 150,
    service: "Junk Removal",
    size: "33% of a truck",
    date: "12/8/20"
  },
  { 
    name: "Emmanuel B", 
    loc: "Mableton, GA", 
    reviewing: "Hullaway, LLC", 
    rating: 5,
    desc: "Chad was awesome with clear explanation on how rates are. Promise delivered. Gave the option of where to leave dumpster.  Will book with Hullaway when another occasion arises.",
    cost: 325,
    service: "Dumpster Rental",
    size: "15 cubic yards",
    date: "12/2/20"
  },
  { 
    name: "Melissa A", 
    loc: "Atlanta, GA", 
    reviewing: "Hullaway, LLC", 
    rating: 5,
    desc: "I couldn’t be happier with the crew that came out. They took care of everything I needed in a very professional and efficient manner. I will definitely be using Hullaway again and recommending them to friends.",
    cost: 200,
    service: "Junk Removal",
    size: "44% of a truck",
    date: "11/30/20"
  },
  { 
    name: "Jennifer H", 
    loc: "Marietta, GA", 
    reviewing: "Junk Octopus", 
    rating: 5,
    desc: "They arrived on time and removed everything they said they would.  The price was fair and they took credit cards, which I liked.  Very well run company.  And a cute logo!",
    cost: 250,
    service: "Junk Removal",
    size: "50% of a truck",
    date: "12/7/20"
  },
  { 
    name: "Dave M", 
    loc: "Decatur, GA", 
    reviewing: "Junk Octopus", 
    rating: 4,
    desc: "I like how quick they were!  They removed everything in record time.  However, they were a bit pricier than other options.",
    cost: 550,
    service: "Junk Removal",
    size: "100% of a truck",
    date: "12/1/20"
  },
  { 
    name: "Mila K.", 
    loc: "Brookhaven, GA", 
    reviewing: "Junk Octopus", 
    rating: 5,
    desc: "The dumpster was delivered on time and pick-up when they said it would be.  We filled it up over a few days so it was a great help to us.  Would certainly recommend this service to friends.  ",
    cost: 335,
    service: "Dumpster Rental",
    size: "15 cubic yards",
    date: "11/21/20"
  },
  { 
    name: "Robert J.", 
    loc: "Roswell, GA", 
    reviewing: "Awesome Junk Guys", 
    rating: 3,
    desc: "These guys really are not awesome.  They arrived very late.  Their truck was a little beat up and was not sure if it would fit our items safely.  In the end, they did fit our items and used a lot of straps to hold everything in.  It looked like it would be unsafe on the road but they assured me that everything was fine.  Their price was the cheapest, but you get what you pay for.",
    cost: 200,
    service: "Junk Removal",
    size: "50% of truck",
    date: "12/7/20"
  },
  { 
    name: "Mark V.", 
    loc: "Buford, GA", 
    reviewing: "G.I. Junk", 
    rating: 4,
    desc: "I used these guys because I was once in the military and I wanted to give them a shot.  They impressed me with the ability to lift and carry my heavy items effortlessly to the truck. I asked if they could donate the items and they said they would, however I never received my donation receipt.  All things considered, they are a good service with good personnel.",
    cost: 375,
    service: "Junk Removal",
    size: "75% of truck",
    date: "12/7/20"
  },
  { 
    name: "Lenny F.", 
    loc: "Lawrenceville, GA", 
    reviewing: "G.I. Junk", 
    rating: 5,
    desc: "This company is great to work with.  I have used them several times in the past.  Every time they have provided great service!",
    cost: 125,
    service: "Junk Removal",
    size: "25% of truck",
    date: "11/14/20"
  },
]
export default function RecentReviews() {
  const reviewNodes = reviews.map(i => <ReviewCard key={`${i.name}-${i.desc}`} {...i} />)
  return (
    <section className={styles.recentReviews}>
        <h2 className={commonStyles.header}>Recent Customer Reviews</h2>
        <div className={styles.reviewSection}>{reviewNodes}</div>
    </section>
  )
}
